import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { SimpleGrid, Checkbox } from '@chakra-ui/react';
import '../styles/pagination.css';

interface PaginationProps {
  state: string
  allItems: any
  itemsPerPage: number
  onClickEvent: any
};

export const Pagination = ({ state, allItems = [], itemsPerPage, onClickEvent }: PaginationProps) => {
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setItemOffset(0);
  }, [state]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allItems.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {currentItems && (
        <SimpleGrid minChildWidth="150px" spacing="15px">
          {currentItems.map((item, index) => <Checkbox
            key={`item-checkbox-${index}`}
            id={`item-checkbox-${index}`}
            value={`${item}, ${state}`}
            onChange={(event) => onClickEvent(state, item, event.target.checked)}>
            {item}
          </Checkbox>)}
        </SimpleGrid>
      )}
      {allItems.length > 50 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageClassName="single-page"
        />
      )}
    </>
  );
};
