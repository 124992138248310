'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  Stack,
  Icon,
  Input,
  Progress,
  Badge
} from '@chakra-ui/react';
import { FiRotateCw } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../App';
import { GOOGLE_API_KEY, COLLECTIONS_LIST, PAGE_CONFIG, PROCESS_STATUS_LIST } from '../utils/constants';
import { getErrorMessage } from '../utils/utils';

export const ImageUpdate = () => {
  const [googlesheetId, setGooglesheetId] = useState<string>('');
  const [imageType, setImageType] = useState<string>('banner');
  const [pagesToProcess, setPagesToProcess] = useState<Array<any>>([]);
  const [processResults, setProcessResults] = useState<any>({
    allRows: 0,
    processed: 0,
    wrongLink: 0,
    imageError: 0,
    strapiError: 0
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [processLog, setProcessLog] = useState<Array<any>>([]);
  const [errorsLog, setErrorsLog] = useState<Array<any>>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { accessToken, config, environment, setEnvironment } = useGeneralContext();

  useEffect(() => {
    resetProcess();
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    if (isProcessing && !errorMessage && currentStep < steps.length) {
      executeStep();
    } else if (currentStep >= steps.length) {
      const timeout = setTimeout(() => {
        setIsProcessing(false);
        setHasFinished(true);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
  }, [isProcessing, errorMessage, currentStep]);

  const resetProcess = () => {
    setGooglesheetId('');
    setImageType('banner');
    setEnvironment('dev');
    setPagesToProcess([]);
    setCurrentStep(0);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setErrorsLog([]);
    setProcessResults({
      allRows: 0,
      processed: 0,
      wrongLink: 0,
      imageError: 0,
      strapiError: 0
    });
  };

  const startStep = (stepName) => setProcessLog(prev => prev.concat({
    step: stepName,
    hasFinished: false,
    status: 'In execution'
  }));

  const completeStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Success';
    }

    return item;
  }));

  const markErrorOnStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Error';
    }

    return item;
  }));

  const executeStep = async () => {
    const step: any = steps[currentStep];

    try {
      const shouldSkipStep = step.name !== 'Fetching data from the file' && !Boolean(pagesToProcess.length);
      if (shouldSkipStep){
        setCurrentStep(prev => prev + 1);
        return;
      }
      
      startStep(step.name);
      // Execute the current step function
      await step.call();
      // Move to the next step
      completeStep(step.name);
      setCurrentStep(prev => prev + 1);
    } catch (error) {
      setErrorMessage(error.message || error);
      markErrorOnStep(step.name);
    }
  };

  const getGooglesheetData = async () => {
    try {
      // Make API request to Google Sheets using the obtained access token
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/A2:B?majorDimension=ROWS&key=${GOOGLE_API_KEY}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (response && response.status === 200 && Boolean(response.data?.values?.length)) {
        const values = response.data.values;
        const pages = values.reduce((acc, curr) => {
          const collectionSplit = curr[0].split(/::([^.]+)/);
          const imageSplit = curr[1].split(/\/file\/d\/([^/]+)/);
          const pageIdSplit = curr[0].split('/');
          const collectionConfig = Object.entries(COLLECTIONS_LIST).find(([name, value]) => value.strapiLink.includes(collectionSplit[1]));
          
          if (collectionConfig) {
            acc.push({
              pageUrl: curr[0],
              pageId: pageIdSplit.slice(-1)[0],
              collection: collectionConfig[0],
              imageUrl: curr[1],
              imageId: imageSplit[1]
            });
          } else {
            setErrorsLog(prev => prev.concat(`Error identifying Strapi page: ${curr[0]}`));
          }
          
          return acc;
        }, []);
        setPagesToProcess(pages);
        setProcessResults(prev => ({
          ...prev,
          allRows: values.length,
          wrongLink: values.length - pages.length
        }));
      } else {
        throw new Error('No data available on the file');
      }
    } catch (error) {
      throw `Loading data from the spreadsheet. ${getErrorMessage(error)}`;
    }
  };

  const uploadImages = async () => {
    const pages = [];
    const initialPagecount = pagesToProcess.length;
    for (const item of pagesToProcess) {
      const strapiImageId = await uploadGoogleDriveImage(item);
      if (strapiImageId) {      
        pages.push({
          ...item,
          strapiId: strapiImageId
        });
      }
    }
    setPagesToProcess(pages);
    setProcessResults(prev => ({
      ...prev,
      imageError: initialPagecount - pages.length
    }));
  };

  const uploadGoogleDriveImage = async (item) => {
    const { pageId, collection, imageUrl } = item;
    const collectionData = COLLECTIONS_LIST[collection];
  
    try {
      // Step 1: Fetch the image from Cloudinary as a Blob
      const imageResponse = await fetch(imageUrl);
      const blob = await imageResponse.blob();

      // Step 2: Convert Blob to a File and create FormData
      const file = new File([blob], 'image.png', { type: blob.type });
      const formData = new FormData();
      formData.append('files', file);

      // Step 3: Add additional information using the fileInfo attribute
      const currentDate = dayjs().format('YYYY-MM-DD');
      const customImageName = `${imageType}Image_${collectionData.api}_${pageId}_${currentDate}`;
      formData.append('fileInfo', JSON.stringify({ name: customImageName }));

      const uploadResponse = await axios.post(`${PAGE_CONFIG.dev.strapiUrl}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${PAGE_CONFIG.dev.token}`,
          'Content-Type': 'multipart/form-data'
        },
      });

      if (uploadResponse && uploadResponse.status === 200 && Boolean(uploadResponse.data?.length)) {
        return uploadResponse.data[0].id;
      }
    } catch (error) {
      setErrorsLog(prev => prev.concat(`Error uploading image to Strapi media library: ${imageUrl}`));
    }
  };

  const assignImages = async () => {
    const pages = [];
    for (const item of pagesToProcess) {
      const updateConfirmation = await updateStrapiData(item);
      if (updateConfirmation) {
        pages.push(item);
      }
    }
    setProcessResults(prev => ({
      ...prev,
      processed: pages.length,
      strapiError: pagesToProcess.length - pages.length
    }));
  };

  const updateStrapiData = async (item) => {
    const { pageUrl, pageId, collection, strapiId } = item;
    const collectionData = COLLECTIONS_LIST[collection];

    try {
      const dataToUpdate = imageType === 'banner' ? { banner: { custom_image: strapiId } } : { meta: { image: strapiId } };
      const updateResponse = await axios.put(`${config.current.strapiUrl}/api/${collectionData.api}/${pageId}`, { data: dataToUpdate }, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });

      if (updateResponse && updateResponse.status === 200 && updateResponse.data?.data?.id) {
        return updateResponse.data.data.id;
      }
    } catch {
      setErrorsLog(prev => prev.concat(`Error updating ${imageType} image to Strapi page: ${pageUrl}`));
    }
  };

  const steps = [
    { name: 'Fetching data from the file', call: () => getGooglesheetData() },
    { name: 'Uploading images to Strapi media library', call: () => uploadImages() },
    { name: 'Assign images to Strapi pages', call: () => assignImages() }
  ];

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Image Update</Heading>
      <Text mb={8}>This process allows you to upload images and update links in batches.</Text>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={8}>
        {!isProcessing && !hasFinished && (
          <Flex background="#F6F8FF" borderRadius="8px" justifyContent="center" py={8}>
            <FormControl w="600px">
              <FormLabel htmlFor="environment" mb={1}>
                Please select the environment you want to work on:
              </FormLabel>            
              <RadioGroup
                id="environment"
                mb={4}
                defaultValue={environment}
                onChange={(event) => setEnvironment(event)}>
                <Stack direction="row" gap={3}>
                  <Radio value="dev">Development</Radio>
                  <Radio value="prod">Production</Radio>
                </Stack>
              </RadioGroup>
              <FormLabel htmlFor="pages-sheet" mb={1}>
                Pages
              </FormLabel>
              <Input
                id="pages-sheet"
                value={googlesheetId}
                background="white"
                onChange={(e) => setGooglesheetId(e.target.value)}
              />
              <Text color="#718096" fontSize="12px" mb={4}>
                Copy and paste Google sheet ID with a list of all the pages to be updated. Sheet needs to have page ID (link to page) mapped with image name.
              </Text>
              <RadioGroup
                id="image-type"
                defaultValue={imageType}
                onChange={(event) => setImageType(event)}>
                <Stack direction="row" gap={3}>
                  <Radio value="banner">Top Banner Image</Radio>
                  <Radio value="meta">Meta Image</Radio>
                </Stack>
              </RadioGroup>
              <Flex justifyContent="center" mt={8}>
                <Button
                  w="150px"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={!googlesheetId}
                  isLoading={isProcessing}
                  onClick={() => setIsProcessing(true)}>
                  Process
                  <Icon boxSize={8} ml={1} as={WiStars} />
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        )}
        {isProcessing && !hasFinished && Boolean(processLog.length) && (<>
          <Heading as="h3" size="md" textAlign="center" mb={6}>Progress</Heading>
          <div>
            {processLog.map((item, index) => (
              <Flex key={`progress-list-${index}`} px="15%" alignItems="center" w="100%" gap="5%" mb={index < processLog.length - 1 ? 6 : 0}>
                <Text w="30%">{`Step ${index + 1}: ${item.step}`}</Text>
                <Progress
                  w="45%"
                  borderRadius="5px"
                  isIndeterminate={!item.hasFinished}
                  value={item.hasFinished ? 100 : 0} />
                <Badge
                  colorScheme={PROCESS_STATUS_LIST[item.status]}
                  borderRadius="5px"
                  textAlign="center"
                  py={1}
                  px={2}
                  w="15%">
                  {item.status}
                </Badge>
              </Flex>
            ))}
          </div>
          {errorMessage && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessage}
                </AlertDescription>
              </Alert>
              <Flex mt={8} justifyContent="center" gap={4}>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resetProcess()}>
                  <Icon boxSize={4} mr={2} as={FiRotateCw} />
                  Start again
                </Button>
              </Flex>
            </Box>
          )}
        </>)}
        {!isProcessing && hasFinished && (
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h3" size="lg" color="#57C600" mb={6}>Success</Heading>
            <Text mb={2}>The images from the file were updated on each Strapi page.</Text>
            <Flex w="full" flexDir="column" mt={3} mb={Boolean(errorsLog.length) ? 7 : 8} justifyContent="center" alignItems="center" gap={4}>
              <Flex gap={4}>
                <Badge p="8px 12px">{`Total items on file: ${processResults.allRows}`}</Badge>
                <Badge colorScheme="green" p="8px 12px">{`Processed items: ${processResults.processed}`}</Badge> 
              </Flex>
              <Flex mt={3} gap={4}>
                <Badge colorScheme="red" p="8px 12px">{`Wrong CMS link: ${processResults.wrongLink}`}</Badge>
                <Badge colorScheme="red" p="8px 12px">{`Error uploading image: ${processResults.imageError}`}</Badge>
                <Badge colorScheme="red" p="8px 12px">{`Error updating Strapi page: ${processResults.strapiError}`}</Badge>
              </Flex>
            </Flex>
            {Boolean(errorsLog.length) && (
              <Box mb={4}>
                {errorsLog.map((error, index) => (
                  <Alert key={`error-list-${index}`} status="error" mb={4}>
                    <AlertIcon />
                    <AlertDescription>
                      {error}
                    </AlertDescription>
                  </Alert>
                ))}
              </Box>
            )}
            <Button
              w="150px"
              variant="outline"
              colorScheme="blue"
              onClick={() => resetProcess()}>
              <Icon boxSize={4} mr={2} as={FiRotateCw} />
              Start again
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
