'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  Stack,
  Icon,
  Input,
  NumberInput,
  NumberInputField,
  Badge,
  Select
} from '@chakra-ui/react';
import { FiRotateCw, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../App';
import { COLLECTIONS_LIST, PAGE_CONFIG, PROCESS_STATUS_LIST } from '../utils/constants';
import { getErrorMessage } from '../utils/utils';

export const PermalinkUpdate = () => {
  const [pageId, setPageId] = useState<string>('');
  const [newPermalink, setNewPermalink] = useState<string>('');
  const [isInvalidPermalink, setIsInvalidPermalink] = useState<boolean>(false);
  const [pagesToProcess, setPagesToProcess] = useState<Array<any>>([]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [processLog, setProcessLog] = useState<Array<any>>([]);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { config, environment, setEnvironment, collection, setCollection } = useGeneralContext();

  useEffect(() => {
    resetProcess();
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    setIsInvalidPermalink(newPermalink && (newPermalink.includes(' ') || !newPermalink?.startsWith('/') || !newPermalink?.endsWith('/')));
  }, [newPermalink]);

  const resetProcess = () => {
    setEnvironment('dev');
    setCollection('STRAPI_AUTO_OTHER');
    setPagesToProcess([]);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
  };

  const processPage = (id, permalink) => setProcessLog(prev => prev.concat({ id, permalink, status: 'In execution' }));

  const completePage = (link) => setProcessLog(prev => prev.map(item => {
    if (item.permalink === link) {
      item.status = 'Success';
    }

    return item;
  }));

  const markErrorOnPage = (link) => setProcessLog(prev => prev.map(item => {
    if (item.permalink === link) {
      item.status = 'Error';
    }

    return item;
  }));

  const addReplacementPermalink = () => {
    setPagesToProcess(prev => [...prev, { pageId, newPermalink }]);
    setPageId('');
    setNewPermalink('');
  };

  const updateStrapiData = async () => {
    setIsProcessing(true);
    const collectionData = COLLECTIONS_LIST[collection];

    try {
      for (const item of pagesToProcess) {
        const { pageId, newPermalink } = item;
        await updatePagePermalink(pageId, newPermalink, collectionData);
      }
    } catch (error) {
      setErrorMessage(`Error updating Strapi dat: ${getErrorMessage(error)}. Please try again.`);
    } finally {
      setIsProcessing(false);
      setHasFinished(true);
    }
  };

  const updatePagePermalink = async (pageId, newPermalink, collectionData) => {
    try {
      processPage(pageId, newPermalink);
      await axios.put(`${config.current.strapiUrl}/api/${collectionData.api}/${pageId}`, { data: { publishing: { permalink: newPermalink }} }, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });
      completePage(newPermalink);
    } catch {
      markErrorOnPage(newPermalink);
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Permalink Update</Heading>
      <Text mb={8}>This process allows you to update permalinks in batches.</Text>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={8}>
        {!isProcessing && !hasFinished && (
          <Box background="#F6F8FF" borderRadius="8px" py={8} px={12}>
            <FormControl display="flex" justifyContent="center" gap={4} mb={6}>
              <div>
                <FormLabel htmlFor="environment" mb={1}>
                  Please select the environment you want to work on:
                </FormLabel>            
                <RadioGroup
                  id="environment"
                  mb={4}
                  defaultValue={environment}
                  onChange={(event) => setEnvironment(event)}>
                  <Stack direction="row" gap={3}>
                    <Radio value="dev">Development</Radio>
                    <Radio value="prod">Production</Radio>
                  </Stack>
                </RadioGroup>
              </div>
              <div>
              <FormLabel htmlFor="collection" mb={1}>
                Collection Name
              </FormLabel>
              <Select id="collection" background="white" value={collection} onChange={(e) => setCollection(e.target.value)}>
                {Object.keys(COLLECTIONS_LIST).map((item, index) => <option key={`collection-${index}`} value={item}>
                  {COLLECTIONS_LIST[item].name}
                </option>)}
              </Select>
              </div>
            </FormControl>
            <Flex alignItems="flex-end" justifyContent="space-between" gap={4} mb={6}>
              <Box maxW="215px">
                <FormLabel htmlFor="page-id" mb={1}>
                  Page ID:
                </FormLabel>
                <NumberInput id="page-id" background="white" value={pageId} min={1} onChange={(e) => setPageId(e)}>
                  <NumberInputField />
                </NumberInput>
              </Box>
              <Box flex={1}>
                <FormLabel htmlFor="new-permalink" mb={1}>
                  New permalink:
                </FormLabel>
                <Input
                  id="new-permalink"
                  background="white"
                  isInvalid={isInvalidPermalink}
                  errorBorderColor="red.300"
                  value={newPermalink}
                  onChange={(e) => setNewPermalink(e.target.value)}
                />
              </Box>
              <Button
                variant="solid"
                colorScheme="teal"
                isDisabled={!pageId || !newPermalink || isInvalidPermalink}
                onClick={() => addReplacementPermalink()}
              >
                <Icon as={FiPlusCircle} />
              </Button>
            </Flex>
            {pagesToProcess.map((item, index) => (
              <Flex key={`replace-permalink-${index}`} alignItems="flex-end" justifyContent="space-between" gap={4} mb={4}>
                <Box maxW="215px"><Input background="white" isReadOnly={true} value={item.pageId}/></Box>
                <Box flex={1}><Input background="white" isReadOnly={true} value={item.newPermalink}/></Box>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={() => setPagesToProcess(prev => prev.filter(({pageId, newPermalink}) => pageId !== item.pageId && newPermalink !== item.newPermalink))}
                >
                  <Icon as={FiMinusCircle} />
                </Button>
              </Flex>
            ))}
            <Flex justifyContent="center" mt={8}>
              <Button
                w="150px"
                variant="solid"
                colorScheme="blue"
                isDisabled={!Boolean(pagesToProcess.length)}
                isLoading={isProcessing}
                onClick={() => updateStrapiData()}>
                Process
                <Icon boxSize={8} ml={1} as={WiStars} />
              </Button>
            </Flex>
          </Box>
        )}
        {(isProcessing || hasFinished) && Boolean(processLog.length) && (<>
          <Heading as="h3" size="md" textAlign="center" mb={6}>Progress</Heading>
          <div>
            {processLog.map((item, index) => (
              <Flex key={`progress-list-${index}`} alignItems="center" justifyContent="center" w="100%" gap="5%" mb={index < processLog.length - 1 ? 6 : 0}>
                <Text>{`Page ${item.id}: ${item.permalink}`}</Text>
                <Badge
                  colorScheme={PROCESS_STATUS_LIST[item.status]}
                  borderRadius="5px"
                  textAlign="center"
                  py={1}
                  px={2}
                  w="170px">
                  {item.status}
                </Badge>
              </Flex>
            ))}
          </div>
          {errorMessage && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessage}
                </AlertDescription>
              </Alert>
            </Box>
          )}
          {hasFinished && (
            <Flex mt={8} justifyContent="center" gap={4}>
              <Button
                w="150px"
                variant="outline"
                colorScheme="blue"
                onClick={() => resetProcess()}>
                <Icon boxSize={4} mr={2} as={FiRotateCw} />
                Start again
              </Button>
            </Flex>
          )}
        </>)}
      </Box>
    </Flex>
  );
};
