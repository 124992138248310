'use client'

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Heading,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  Text
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { useGeneralContext } from '../App';
import { ProgressBar } from '../components/ProgressBar';
import { PAGE_CONFIG } from '../utils/constants';

const COLUMN_NAMES = {
  state: 'State',
  city: 'State',
  company: 'Company',
  'cc-versus': 'CC versus page',
  model: 'Model'
};

const DUPLICATION_PAGES = {
  state: '/duplicator/flex',
  city: '/duplicator/flex',
  company: '/duplicator/flex/company',
  'cc-versus': '/duplicator/template/cc',
  model: '/duplicator/flex/model'
};

export const CreatedPages = () => {
  const { config, pageData, resultPages, currentProcess, operationType, environment, setEnvironment } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageData || !Boolean(resultPages.length)) {
      navigate('/');
    }
  }, [pageData, resultPages]);

  const handleCompleteProcess = (isSuccessful) => {
    setEnvironment('dev');
    config.current = PAGE_CONFIG[environment];

    if (isSuccessful) {
      navigate('/');
    } else {
      navigate(DUPLICATION_PAGES[operationType]);
    }
  };

  const getComponentsDistribution = (flexZone) => {
    const componentsDistribution = flexZone.reduce((acc, curr) => {
      const componentName = curr['__component'];
      if (componentName in acc) {
        acc[componentName] += 1;
      } else {
        acc[componentName] = 1;
      }
      return acc;
    }, {});

    return Object.keys(componentsDistribution).map((item, index) => <Flex key={`components-list-${index}`}>
      {item}:<Text fontWeight={600} ml={1}>{componentsDistribution[item]}</Text>
    </Flex>);
  };

  const getPageName = (replace) => {
    if (operationType === 'city') {
      return (<>
        <Td>{replace.state}</Td>
        <Td>{replace.city}</Td>
      </>);
    } else if (operationType === 'cc-versus') {
      return <Td>{`${replace.card_1.newName} Vs. ${replace.card_2.newName}`}</Td>
    } else if (operationType === 'company') {
      return <Td>{replace.label}</Td>
    } else {
      return <Td>{replace}</Td>
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName={currentProcess} currentStep={currentProcess === 'pageDuplication' ? 2 : 1} />
      <Heading textAlign="center" mb={10}>{`Step ${currentProcess === 'pageDuplication' ? '3' : '2'}: Preview Created Pages`}</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        {!resultPages.length ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Unexpected error:</AlertTitle>
            <AlertDescription>Please check all settings for the process and try again.</AlertDescription>
          </Alert>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Strapi CMS Link</Th>
                  <Th>{COLUMN_NAMES[operationType]}</Th>
                  {operationType === 'city' && <Th>City</Th>}
                  <Th>Components Validation</Th>
                  <Th>MoneyGeek Link</Th>
                </Tr>
              </Thead>
              <Tbody>
                {resultPages.map((item, index) => (
                  <Tr key={`pages-list${index}`} bg={item.strapiLink ? "green.100" : "red.100"}>
                    <Td>
                      {!item.id ? 'Failed' : (
                        <Link display="flex" alignItems="center" gap={1} href={item.strapiLink} isExternal>
                          <b>{item.id}</b> <Icon as={FiExternalLink} />
                        </Link>
                      )}
                    </Td>
                    {getPageName(item.replace)}
                    <Td>
                      <Popover>
                        <PopoverTrigger>
                          <Badge _hover={{ cursor: 'pointer' }}>{item.flex_zone?.length} of {pageData.flex_zone?.length} processed</Badge>
                        </PopoverTrigger>
                        <PopoverContent w="auto">
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader fontWeight={600}>Components list</PopoverHeader>
                          <PopoverBody>
                            <Flex>
                              <Box>{getComponentsDistribution(item?.flex_zone)}</Box>
                              <Divider orientation="vertical" h="auto" mx={2} />
                              <Box>{getComponentsDistribution(pageData?.flex_zone)}</Box>
                            </Flex>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Td>
                    <Td>
                      {!item.id ? 'No link available' : (
                        <Link display="flex" alignItems="center" gap={1} href={item.siteLink} isExternal>
                          {item.siteLink} <Icon as={FiExternalLink} />
                        </Link>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Button variant="solid"
        colorScheme="blue"
        type="submit"
        mt={4}
        onClick={() => handleCompleteProcess(resultPages.length)}>
          {!resultPages.length ? 'Return to first step' : 'Complete Process'}
      </Button>
    </Flex>
  );
};
