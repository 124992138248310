'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Heading,
  Flex,
  Box,
  SimpleGrid,
  Divider,
  Text,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { useGeneralContext } from '../../App';
import { ProgressBar } from '../../components/ProgressBar';
import { ComponentBox } from '../../components/ComponentBox';
import { Confirmation } from '../../components/Confirmation';
import { STATES_LIST, COLLECTIONS_LIST } from '../../utils/constants';
import { replaceMultipleStrings, getReplacements, cleanRelationFields, formatDate } from '../../utils/utils';

export const StaticComponents = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [componentsToModify, setComponentsToModify] = useState<any>({
    permalink: true,
    name: true,
    breadcrumb: false,
    banner: false,
    meta: false,
    hex: false
  });

  const {
    config,
    collection,
    pageData,
    urlPattern,
    operationType,
    stateToProcess,
    cityToProcess,
    companyToProcess,
    modelToProcess,
    itemsToReplicate,
    setResultPages,
    monthToReplace,
    currentDateFilter,
    newMonth,
    newDateFilter,
    modifyPageDate
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageData || !Boolean(itemsToReplicate.length)) {
      navigate('/');
    }

    setResultPages([]);
  }, [pageData, stateToProcess]);

  const handleProcess = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    try {
      const updatedValues = await Promise.all(itemsToReplicate.map(item=> new Promise((res) => res(getUpdatedCopyData(item)))));
      await Promise.all(updatedValues.map((page, index) => new Promise((res) => res(createCopyPage(page, index)))));
      navigate('/components/dynamic');
    } catch(e) {
      setErrorMessage(e.response && e.response.headers || e.request || e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onCheckboxChange = (name, value) => {
    setComponentsToModify({
      ...componentsToModify,
      [name]: value
    });
  };

  const onCheckAllChange = (name, value) => {
    setComponentsToModify({
      permalink: true,
      name: true,
      breadcrumb: value,
      banner: value,
      meta: value,
      hex: value
    });
  };
  
  const getUpdatedCopyData = (item) => {
    const originalValues = {
      state: stateToProcess,
      city: cityToProcess,
      company: companyToProcess,
      model: modelToProcess
    };
    const customReplacements = modifyPageDate ? {
      [monthToReplace]: newMonth,
      [formatDate(currentDateFilter)]: formatDate(newDateFilter)
    } : {};
    const replacements = getReplacements(originalValues, item, operationType, customReplacements);
    const updatedPermalink = getUpdatedPermalink(item);
    const updatedBreadcrumb = replaceMultipleStrings(pageData.publishing?.breadcrumb, replacements);
    const updatedBannerTitle = replaceMultipleStrings(pageData.banner?.title, replacements);
    const updatedBannerSubtitle = replaceMultipleStrings(pageData.banner?.subtitle, replacements);
    const updatedBannerDescription = replaceMultipleStrings(pageData.banner?.description, replacements);
    const updatedMetaTitle = replaceMultipleStrings(pageData.meta?.title, replacements);
    const updatedMetaDescription = replaceMultipleStrings(pageData.meta?.description, replacements);
    const updatedHexSettings = pageData.hex_settings ? getUpdatedHexSettings(pageData.hex_settings, replacements) : null;

    delete pageData.publishing?.id;
    delete pageData.banner?.id;
    delete pageData.meta?.id;

    return {
      ...pageData,
      publishedAt: null,
      name: replaceMultipleStrings(pageData.name, replacements),
      authors: cleanRelationFields(pageData.authors),
      experts: cleanRelationFields(pageData.experts),
      reviewed_by: cleanRelationFields(pageData.reviewed_by),
      verified_by: cleanRelationFields(pageData.verified_by),
      kevel: cleanRelationFields(pageData.kevel),
      editor: cleanRelationFields(pageData.editor),
      flex_zone: cleanRelationFields([...pageData.flex_zone]),
      syndication: cleanRelationFields(pageData.syndication),
      sources: pageData.sources?.map(item => {
        delete item.id;
        return item;
      }),
      publishing: {
        ...pageData.publishing,
        permalink: updatedPermalink,
        breadcrumb: componentsToModify.breadcrumb ? updatedBreadcrumb : pageData.publishing?.breadcrumb
      },
      banner: {
        ...pageData.banner,
        custom_image: cleanRelationFields(pageData.banner?.custom_image),
        title: componentsToModify.banner ? updatedBannerTitle : pageData.banner?.title,
        subtitle: componentsToModify.banner ? updatedBannerSubtitle : pageData.banner?.subtitle,
        description: componentsToModify.banner ? updatedBannerDescription : pageData.banner?.description
      },
      meta: {
        ...pageData.meta,
        title: componentsToModify.meta ? updatedMetaTitle : pageData.meta?.title,
        description: componentsToModify.meta ? updatedMetaDescription : pageData.meta?.description,
        image: cleanRelationFields(pageData.meta?.image)
      },
      hex_settings: componentsToModify.hex ? updatedHexSettings : pageData.hex_settings,
      ...collection === 'STRAPI_CREDIT_FLEX_PAGE' && {
        versus_cards: null,
        review_card: cleanRelationFields(pageData.review_card)
      }
    };
  };

  const getUpdatedPermalink = (replaceItem) => {
    let replacements = {};

    if (operationType === 'company') {
      const originalFormattedCompany = companyToProcess.label.replaceAll(' ', '-').toLowerCase();
      replacements = {
        [originalFormattedCompany]: replaceItem.label.replaceAll(' ', '-').toLowerCase()
      };
    } else if (operationType === 'model') {
      const originalFormattedModel = modelToProcess.replaceAll(' ', '-').toLowerCase();
      replacements = {
        [originalFormattedModel]: replaceItem.replaceAll(' ', '-').toLowerCase()
      };
    } else {
      const originalFormattedState = stateToProcess.replaceAll(' ', '-').toLowerCase();
      const originalFormattedCity = cityToProcess.replaceAll(' ', '-').toLowerCase();
      const abbreviatedState = STATES_LIST[stateToProcess].toLowerCase();
      
      if (operationType === 'city') {
        replacements = {
          [originalFormattedState]: replaceItem.state.replaceAll(' ', '-').toLowerCase(),
          [originalFormattedCity]: replaceItem.city.replaceAll(' ', '-').toLowerCase(),
          [abbreviatedState]: STATES_LIST[replaceItem.state].toLowerCase()
        };
      } else {
        replacements = {
          [originalFormattedState]: replaceItem.replaceAll(' ', '-').toLowerCase(),
          [abbreviatedState]: STATES_LIST[replaceItem].toLowerCase()
        };
      }
    }

    const newPermalink = replaceMultipleStrings(urlPattern, replacements);
    return newPermalink.toLowerCase();
  };

  const getUpdatedHexSettings = (settings, replacements) => {
    const { general } = settings;

    if (!general) {
      return settings;
    }

    const updatedGeneral = Object.entries(general).reduce((acc, [currKey, currValue]) => {
      acc[currKey] = replaceMultipleStrings(currValue, replacements)

      return acc;
    }, {});

    return {
      ...settings,
      general: updatedGeneral
    }
  };

  const createCopyPage = async (newPage, index) => {
    const pageConfirmation = {
      replace: itemsToReplicate[index],
      siteLink: config.current.sitePreviewUrl + newPage.publishing.permalink,
      flex_zone: newPage.flex_zone
    };

    await axios.post(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}`, { data: newPage }, {
      headers: {
        Authorization: `Bearer ${config.current.token}`
      }
    }).then(result =>  {
      pageConfirmation['strapiLink'] = config.current.strapiUrl + COLLECTIONS_LIST[collection].strapiLink + result.data?.data?.id;
      pageConfirmation['id'] = result.data?.data?.id;
      setResultPages((prev) => [...prev, pageConfirmation]);
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={1} />
      <Heading textAlign="center" mb={10}> Select Content Components</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" py={6} px={44}>
        {errorMessage && (
          <Alert status="error" mb={6}>
            <AlertIcon />
            <AlertTitle>Strapi error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        <SimpleGrid gridTemplateColumns="repeat(3, 1fr)" gridGap={5}>
          <ComponentBox name="all" icon="select" onCheckboxChange={onCheckAllChange} />
          <ComponentBox name="name" icon="settings" isDefault={true} isSelectedByParent={componentsToModify.name} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="permalink" icon="link" isDefault={true} isSelectedByParent={componentsToModify.permalink} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="breadcrumb" icon="settings" isSelectedByParent={componentsToModify.breadcrumb} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="banner" icon="title" isSelectedByParent={componentsToModify.banner} onCheckboxChange={onCheckboxChange} />
          <ComponentBox name="meta" isSelectedByParent={componentsToModify.meta} onCheckboxChange={onCheckboxChange} />
          {pageData?.hex_settings && <ComponentBox name="hex" isSelectedByParent={componentsToModify.meta} onCheckboxChange={onCheckboxChange} />}
        </SimpleGrid>
        <Divider my={6} />
        <Text fontWeight="bold" mb={4}>
          {`Data found on the ${operationType === 'company' ? companyToProcess.label :
            operationType === 'city' ? cityToProcess + ', ' + stateToProcess : stateToProcess} template page`}
        </Text>
        {pageData && (
          <UnorderedList>
            <ListItem><b>Page Name: </b>{pageData.name}</ListItem>
            <ListItem><b>Permalink: </b>{pageData.publishing?.permalink}</ListItem>
            <ListItem><b>Breadcrumb: </b>{pageData.publishing?.breadcrumb}</ListItem>
            <ListItem><b>Banner title: </b>{pageData.banner?.title}</ListItem>
            <ListItem><b>Meta title: </b>{pageData.meta?.title}</ListItem>
            <ListItem><b>Meta description: </b>{pageData.meta?.description}</ListItem>
          </UnorderedList>
        )}
      </Box>
      <Confirmation
        buttonLabel="Create Pages"
        bodyDescription={`Please check template data and selected components to replace before moving on.\n
        Page Name: Yes.\n
        Permalink: Yes.\n
        Breadcrumb: ${componentsToModify.breadcrumb ? 'Yes' : 'No'}.\n
        Banner: ${componentsToModify.banner ? 'Yes' : 'No'}\n
        Meta: ${componentsToModify.meta ? 'Yes' : 'No'}\n
        HEX Settings: ${componentsToModify.hex ? 'Yes' : 'No'}`}
        onClickEvent={() => handleProcess()}
        shouldBeLoading={isProcessing}
        shouldBeDisabled={false} />
    </Flex>
  );
};
