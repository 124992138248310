import { useState, useEffect, useRef } from 'react';
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

interface FormModalProps {
  title?: string
  buttonLabel: string
  bodyDescription: string
  onOpenModal?: any
  onClickEvent: any
  color?: string
  icon?: any
  children: any
  shouldBeLoading?: boolean
  shouldBeDisabled?: boolean
  disableEvent?: boolean
};

export const FormModal = ({
  title,
  buttonLabel,
  bodyDescription,
  onOpenModal = null,
  onClickEvent,
  color = "blue",
  icon = null,
  children,
  shouldBeLoading = false,
  shouldBeDisabled = false,
  disableEvent = false
}: FormModalProps) => {
  const [processResult, setProcessResult] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    setProcessResult(null);
  }, [isOpen]);

  const onOpenEvent = () => {
    if (onOpenModal) {
      onOpenModal();
    }
    
    onOpen();
  };

  const onHandleClick = async () => {
    try {
      await onClickEvent();
      setProcessResult({ status: 'success', message: 'Process completed.' });
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      setProcessResult({ status: 'error', message: error.message });
    }
  };

  return (<>
    <Button
      variant="solid"
      colorScheme={color}
      isDisabled={shouldBeDisabled}
      isLoading={shouldBeLoading}
      onClick={() => onOpenEvent()}
    >
      {buttonLabel}
      {icon && <Icon as={icon} ml={2} />}
    </Button> 
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title || 'Create new item'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody whiteSpace="pre-line">
          {bodyDescription}
          {children}
          {processResult && (
            <Alert status={processResult.status} mt={2}>
              <AlertIcon />
              <AlertTitle textTransform="capitalize">{processResult.status}:</AlertTitle>
                <AlertDescription>{processResult.message}</AlertDescription>
            </Alert>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            No
          </Button>
          <Button colorScheme="green" ml={3} isDisabled={disableEvent} onClick={() => onHandleClick()}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>);
};
